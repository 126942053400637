// used to get error string for actions (not pages)
// shown using snackbar

export default function ErrorString(response) {
  let str = 'An error occurred'
  // no block for 404, uses default error string
  if (!response) {
    return 'Could not connect to server. Please check your internet connection.' // response null
  }
  if (response.status === 500)
    str =
      'An error occurred. Our technical team has been informed. Please check back after some time.'
  else if (response.status === 403)
    str = 'You are not allowed to perform this action.'
  else if (response.status === 406)
    str = 'Something went wrong. Please try again.'
  else if (response.status === -1)
    str = 'Could not connect to server. Please check your internet connection.'
  try {
    const {
      data: {
        error: { readable },
      },
    } = response
    if (readable) {
      str = readable
      return str
    }
  } catch (e) {
    return str
  }
  return str
}
