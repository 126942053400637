import * as Sentry from '@sentry/browser'
import { Integrations as ApmIntegrations } from '@sentry/apm'

const load = (callback) => {
  if (process.env.DEBUG === true) return
  callback()
}

export default {
  init() {
    load(() => {
      Sentry.init({
        ignoreErrors: [
          'Could not find more button element', // https://github.com/tinymce/tinymce/issues/5100 ignoring for now
          'The component must be in a context to send: triggerEvent', // another tinymce error,
          "Failed to execute 'postMessage' on 'Worker'", // pdfjs worker error
          /getReadMode(.*)/, // some weird errors like getReadModeConfig, getReadModeExtract...
          'Worker was terminated',
          'The user aborted a request',
        ],
        dsn:
          'https://bc968d41dd344148bae3b95c528763fc@o70506.ingest.sentry.io/151196',
        integrations: [
          new ApmIntegrations.Tracing({ tracingOrigins: ['api.ylurn.com'] }),
        ],
        tracesSampleRate: 0.2,
        /*
        beforeSend(event) {
          // Check if it is an exception, and if so, show the report dialog
          if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
          }
          return event;
        },
        */
      })
    })
  },
  login(user) {
    if (!user) return
    load(() => {
      Sentry.configureScope((scope) => {
        scope.setUser({ email: user.email, name: user.name })
      })
    })
  },
  logout() {
    load(() => {
      Sentry.configureScope((scope) => {
        scope.setUser()
      })
    })
  },
  captureException(error) {
    load(() => {
      Sentry.captureException(error)
    })
  },
}
