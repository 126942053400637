import axios from 'axios'

const logglyAxios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

export default {
  log: (data) => {
    // can send string or json as data
    // eslint-disable-next-line prefer-destructuring
    const DEBUG = process.env.DEBUG
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log(data)
    } else {
      logglyAxios
        .post(
          'https://logs-01.loggly.com/inputs/a99c9b90-cfe7-4c73-8937-252cb05a49ad/tag/http/',
          { client: 'website', error: data }
        )
        .then(() => {
          // handle success
        })
        .catch(() => {
          // handle error
          // eslint-disable-next-line no-console
          console.log('loggly error')
        })
        .then(() => {
          // always executed
        })
    }
  },
}
