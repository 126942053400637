import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false })

let loadCount = 0

const minLoadAnimate = 50
// makes sure that the timeout is called after this many ms
// this interval is sufficient for next request to be made
// which is only triggered when the first one gets completed
let setLoadPrevVal = 0

function load() {
  if (loadCount === 0) NProgress.start()
  loadCount += 1
}

function loadTimeout() {
  if (loadCount === 0) NProgress.done()
}

function done() {
  loadCount -= 1
  if (loadCount === 0) setTimeout(loadTimeout, minLoadAnimate)
  if (loadCount < 0) loadCount = 0
}

function setLoad(val) {
  // progress in %
  const num = Math.floor(val / 100)
  if (num === setLoadPrevVal) return
  setLoadPrevVal = num
  if (num !== 100) NProgress.set(num)
  else {
    NProgress.done()
    setLoadPrevVal = 0
  }
}

function forceDone(time) {
  setTimeout(() => {
    NProgress.done()
    loadCount = 0
  }, time)
}

export default {
  load,
  done,
  setLoad,
  forceDone,
}
